
import { defineComponent, PropType } from 'vue'
import { DspInstruction, IoBrief } from '../../../types/instruction_type'

export default defineComponent({
  name: 'LocalizationColumn',
  props: {
    io: {
      type: Object as PropType<IoBrief<DspInstruction>>,
      required: true
    }
  },
  data: function () {
    return { }
  },
  methods: {
    getIo () {
      return this.io.id
    },
    getIoName () {
      return this.io.overview_name
    },
    getAdvertiser () {
      return this.io.advertiser_id
    },
    getAdvertiserName () {
      return this.io.advertiser
    },
    openIo () {
      this.$emit('open-io')
    }
  }
})

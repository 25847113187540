import { InstructionAppnexus, InstructionDsp } from '../../../../../../types/instruction_type'
import {
  $APPNEXUS,
  $DBM,
  $BEESWAX,
  $META,
  $FACEBOOK,
  $THETRADEDESK,
  $YOUTUBE,
  $MEDIAMATH
} from '../../../../../../config/dspConfig'
import {
  tooltipFunction, tooltipFunctionTarget,
  unknownDemographicConf
} from '@/components/TableComponents/Line/Fragments/DSPOverwriting/dspOverwritingUtils'
import { DspOverwritingItem } from '../../../../../../types/surcouche_v2_types'

export const overwriteData: {[K in InstructionDsp]: DspOverwritingItem[]} = {
  [$APPNEXUS]: [
    {
      label: 'overwrite frequency',
      value: 'overwrite_frequency',
      tooltip: tooltipFunction,
      isOrange: tooltipFunction
    },
    {
      label: 'overwrite timeparting',
      value: 'remove_time_parting'
    },
    {
      label: 'overwrite budget <span class="overwrite-info-gl-cp">global</span>',
      value: 'remove_daily_budget_global',
      displayRules: (props: InstructionAppnexus) => {
        return props.remove_daily_budget_global === true
      }
    },
    {
      label: 'overwrite budget <span class="overwrite-info-gl-cp">campaigns</span>',
      value: 'remove_daily_budget_campaigns_only',
      displayRules: (props: InstructionAppnexus) => {
        return props.remove_daily_budget_campaigns_only === true
      }
    },
    {
      label: 'overwrite budget',
      value: '',
      displayRules: (props: InstructionAppnexus) => {
        return (props.remove_daily_budget_campaigns_only === undefined || props.remove_daily_budget_campaigns_only === false) &&
          (props.remove_daily_budget_global === undefined || props.remove_daily_budget_global === false)
      }
    },
    {
      label: 'overwrite creative selection',
      value: 'overwrite_creative_selection'
    },
    {
      label: 'overwrite trusted inventories',
      value: 'keep_trusted_inventory',
      isAdvanced: true,
      reverseBoolean: true
    },
    {
      label: 'overwrite fold position',
      value: 'remove_fold_position',
      isAdvanced: true
    },
    {
      label: 'overwrite viewability',
      value: 'remove_min_viz',
      isAdvanced: true
    },
    {
      label: 'overwrite min completion',
      value: 'remove_min_crate',
      isAdvanced: true
    },
    {
      label: 'Force LI daily pacing ASAP',
      value: 'force_li_daily_pacing_asap',
      isAdvanced: true
    }
  ],
  [$DBM]: [
    {
      label: 'overwrite frequency',
      value: 'overwrite_frequency',
      tooltip: tooltipFunction,
      isOrange: tooltipFunction
    },
    {
      label: 'overwrite timeparting',
      value: 'keep_timeparting',
      reverseBoolean: true
    },
    {
      label: 'overwrite viewability',
      value: 'keep_min_viz',
      reverseBoolean: true
    },
    {
      label: 'force pacing asap',
      value: 'force_pacing_asap_li'
    },
    {
      label: 'overwrite daily budget li',
      value: 'overwrite_daily_budget_li'
    },
    {
      label: 'remove fold position',
      value: 'remove_fold_position'
    },
    {
      label: 'overwrite creative selection',
      value: 'overwrite_creative_selection'
    },
    {
      label: 'automatically remove deprecated url',
      value: 'automatically_remove_deprecated_url'
    },
    unknownDemographicConf
  ],
  [$MEDIAMATH]: [
    {
      label: 'overwrite frequency',
      value: 'overwrite_frequency',
      tooltip: tooltipFunction,
      isOrange: tooltipFunction
    },
    {
      label: 'remove fold position',
      value: 'remove_fold_position'
    },
    {
      label: 'remove timeparting',
      value: 'remove_timeparting'
    },
    {
      label: 'remove budget strat money',
      value: 'remove_budget_strat_money'
    },
    {
      label: 'remove budget strat imp',
      value: 'remove_budget_strat_imp'
    },
    {
      label: 'force pacing asap money',
      value: 'force_pacing_asap_money'
    },
    {
      label: 'force pacing asap imp',
      value: 'force_pacing_asap_imp'
    }
  ],
  [$THETRADEDESK]: [
    {
      label: 'overwrite frequency',
      value: 'overwrite_frequency',
      tooltip: tooltipFunction,
      isOrange: tooltipFunction
    },
    {
      label: 'remove timeparting',
      value: 'remove_timeparting'
    },
    {
      label: 'remove fold position',
      value: 'remove_fold_position'
    },
    {
      label: 'overwrite daily budget money adgroup',
      value: 'overwrite_daily_budget_money_adgroup'
    },
    {
      label: 'overwrite daily budget impressions adgroup',
      value: 'overwrite_daily_budget_impressions_adgroup'
    },
    {
      label: 'overwrite viewability',
      value: 'overwrite_viewability'
    }
  ],
  [$YOUTUBE]: [
    {
      label: 'overwrite frequency',
      value: 'overwrite_frequency',
      tooltip: tooltipFunction,
      isOrange: tooltipFunction
    },
    {
      label: 'overwrite frequency target',
      value: 'overwrite_frequency_target',
      tooltip: tooltipFunctionTarget,
      isOrange: tooltipFunctionTarget
    },
    {
      label: 'overwrite li budget',
      value: 'overwrite_li_budget'
    },
    {
      label: 'automatically remove deprecated url',
      value: 'automatically_remove_deprecated_url'
    },
    {
      label: 'overwrite bid adjustments on device',
      value: 'overwrite_bid_adjustments_on_device'
    },
    {
      label: 'remove time parting',
      value: 'remove_time_parting'
    },
    unknownDemographicConf,
    {
      label: 'Allow switch IO pacing to ASAP',
      value: 'allow_switch_io_pacing'
    }
  ],
  [$BEESWAX]: [
    {
      label: 'overwrite frequency',
      value: 'overwrite_frequency',
      tooltip: tooltipFunction,
      isOrange: tooltipFunction
    }
  ],
  [$FACEBOOK]: [
    {
      label: 'use adset budgets',
      value: 'use_adset_budgets',
      tooltip: tooltipFunction,
      isOrange: tooltipFunction
    }
  ],
  [$META]: [
    {
      label: 'Overwrite campaign budget',
      value: 'overwrite_campaign_budget',
      tooltip: tooltipFunction,
      isOrange: tooltipFunction
    }
  ]
} as Record<InstructionDsp, DspOverwritingItem[]>

<template>
  <BaseDSPOverwriting
    :props="props"
    :overwrite-data="overwriteData">
  </BaseDSPOverwriting>
</template>

<script>
import BaseDSPOverwriting from '@/components/TableComponents/Line/Fragments/DSPOverwriting/BaseDSPOverwriting.vue'
import { overwriteData } from '@/components/TableComponents/Line/Fragments/DSPOverwriting/dspOverwritingConstants'

export default {
  name: 'DSPOverwriting',
  props: ['props'],
  components: {
    BaseDSPOverwriting
  },
  data: function () {
    return {
      /**
       * advanced : value are not displayed is they are equal to the default value.
       * reverse boolean : In contrary to other item, reverse boolean are ON when false, and OFF when true
       */
      overwriteData: overwriteData
    }
  }
}
</script>

<style>
.overwrite-info-gl-cp {
  color: crimson;
}
</style>
